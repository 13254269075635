import store from '../../index';
import { PregenerateClaimsFeild } from '../../../../types/MarketingBox';
import { ParamsUsed } from '../../../../types/store/markettingContentGeneration/majorProcessActions';
import {
  getEncodedUserNtid,
  getGenerateContentPayload,
  getParamsUsed,
  resetTranslationState,
} from '../../helpers/common';
import { contentListStateSegment } from '../../stateSegments/contentListSegment';
import { fetchGeneratedContent } from '../../../../services/Content';
import { DSPGeneratedContent } from '../../../../components/molecules/DSPRenderer/DSPRenderer.types';
import {
  ANALYTICS,
  ContentType,
  REGENERATION_LIMIT,
  summarizationStatuses,
} from '../../../../constants/common';
import {
  trackConfidenceScore,
  trackConfidenceScoreForPrimaryMessage,
  trackContentConfidenceScore,
  trackGenerateContentComplete,
  trackGenerateContentInitiate,
  trackInputValues,
  trackSecondaryClaims,
  trackSystemErrors,
} from '../../../../services/Analytics';
import { getPrimaryLanguage } from '../../../../services/SSO';
import {
  emailContentParentId,
  moduleContentsStateSegment,
} from '../../stateSegments/emailModulesStateSegment';
import { generateStateSegment } from '../../stateSegments/generateStateSegment';

export const generateContent = async (
  paramsUsed: ParamsUsed,
  claimsDetail: PregenerateClaimsFeild,
  mapKey: string = ''
) => {
  resetTranslationState();

  store.set(contentListStateSegment, (prev) => {
    return {
      ...prev,
      isGeneratingContent: true,
      retryPromptParams: paramsUsed,
    };
  });

  const generatePayload = getGenerateContentPayload(paramsUsed);

  const payload = {
    ...generatePayload,
    ...claimsDetail,
  };
  const { success, data } = await fetchGeneratedContent(payload);

  const summarizationStatus = (data?.content as DSPGeneratedContent)
    ?.contentSummary?.summarizationStatus;

  const isDspSummarizationInitiated =
    payload.contentType === ContentType.DSP &&
    summarizationStatus === summarizationStatuses.SUMMARIZATION_INITIATED;

  const isDspError =
    payload.contentType === ContentType.DSP &&
    summarizationStatus === summarizationStatuses.SUMMARIZATION_ERROR;

  if (isDspError) {
    store.set(contentListStateSegment, (prev) => {
      return {
        ...prev,
        isGeneratingContent: false,
        errorGeneratingContent: true,
        errorMessage: 'There has been an error in generating content',
      };
    });
    trackSystemErrors(ANALYTICS.GENERATE_FORM_NAME);
    return;
  }

  function trackAnalytics() {
    const { brand, contentType, topics, targetAudience, destinationFormat } =
      payload;
    const { useSubstituteClaims, ...restPayload } = payload;
    const generateInputValues = {
      ...restPayload,
      'secondary-claims': useSubstituteClaims ? ANALYTICS.YES : ANALYTICS.NO,
      language: getPrimaryLanguage(),
      [ANALYTICS.USER_ENCODED_NTID_QUESTION_NAME]: getEncodedUserNtid(),
    };

    const channel =
      (contentType === ContentType.DSP && destinationFormat) || contentType;

    // generate content initiate is tracked for each email module generate
    if (channel.toLowerCase() === ContentType.EMAIL.toLowerCase()) {
      trackGenerateContentInitiate(ANALYTICS.GENERATE_FORM_NAME);
    }

    trackInputValues(
      ANALYTICS.GENERATE_FORM_NAME,
      ANALYTICS.STEP,
      generateInputValues
    );

    if (useSubstituteClaims) {
      trackSecondaryClaims(
        ANALYTICS.SECONDARY_CLAIMS_FORM_NAME,
        brand,
        contentType,
        targetAudience,
        getEncodedUserNtid()
      );
    }

    if (data?.score?.value) {
      trackContentConfidenceScore(
        ANALYTICS.GENERATE_FORM_NAME,
        data?.score?.value,
        Object.keys(generateInputValues).length + 1
      );
      trackConfidenceScore(
        ANALYTICS.CONFIDENCE_SCORE_FORM_NAME,
        brand,
        getEncodedUserNtid(),
        data?.score?.value
      );
      if (topics) {
        trackConfidenceScoreForPrimaryMessage(
          ANALYTICS.CONFIDENCE_SCORE_FORM_NAME,
          topics,
          data?.score?.value
        );
      }
    }

    if (channel.toLowerCase() !== ContentType.DSP.toLowerCase()) {
      trackGenerateContentComplete(
        ANALYTICS.GENERATE_FORM_NAME,
        brand,
        contentType,
        getEncodedUserNtid()
      );
    }
  }

  if (success && !isDspSummarizationInitiated) {
    const { contentType, destinationFormat } = payload;

    store.set(contentListStateSegment, (prev) => {
      const content = {
        [getPrimaryLanguage()]: data,
        paramsUsed: getParamsUsed(paramsUsed),
        regenerationLimit: REGENERATION_LIMIT - 1,
        apiPayloadUsed: payload,
      };

      return {
        ...prev,
        errorGeneratingContent: false,
        errorMessage: '',
        isGeneratingContent: false,
        contentList: [content] as never,
      };
    });
    if (store.get(emailContentParentId) === '')
      store.set(emailContentParentId, data?.id || '');

    if (
      contentType === 'email' ||
      destinationFormat?.toLowerCase() === 'email'
    ) {
      const moduleContents = new Map(store.get(moduleContentsStateSegment));
      const module = moduleContents.get(mapKey);
      if (module) {
        moduleContents.set(mapKey, {
          ...module,
          generatePayload: payload,
          parentContentId: data?.id || '',
          hasContentGenerated: true,
          translationConfig: {
            '0': getPrimaryLanguage(),
          },
        });
      }
      store.set(moduleContentsStateSegment, moduleContents);
    }

    trackAnalytics();
  } else if (success && isDspSummarizationInitiated) {
    const content = data?.content as DSPGeneratedContent;
    store.set(generateStateSegment, {
      contentId: content.id,
      generateContentInitiated: true,
      paramsUsed,
      primaryLanguage: getPrimaryLanguage(),
      apiPayloadUsed: payload,
    });
    store.set(contentListStateSegment, (prev) => {
      return {
        ...prev,
        errorGeneratingContent: false,
        errorMessage: '',
        isGeneratingContent: true,
      };
    });

    trackAnalytics();
  } else {
    const { contentType, destinationFormat } = payload;

    store.set(contentListStateSegment, (prev) => {
      return {
        ...prev,
        isGeneratingContent: false,
        errorGeneratingContent: true,
        errorMessage: 'There has been an error in generating content',
      };
    });
    trackSystemErrors(ANALYTICS.GENERATE_FORM_NAME);

    if (
      contentType === 'email' ||
      destinationFormat?.toLowerCase() === 'email'
    ) {
      const moduleContents = new Map(store.get(moduleContentsStateSegment));
      const module = moduleContents.get(mapKey);

      if (module) {
        moduleContents.set(mapKey, {
          ...module,
          generatePayload: null,
          parentContentId: '',
          hasContentGenerated: false,
          hasErrorGeneratingContent: true,
        });
      }
      store.set(moduleContentsStateSegment, moduleContents);
    }
  }
};
