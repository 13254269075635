import get from 'lodash/get';
import { TranslatedData } from '../store/markettingContentGeneration/stateSegments/translationSegment';
import { GeneratedModuleContent, TranslatedContent } from '../types/Content';
import { Option } from '../types/SelectInput';

// Used for Current Email Module Export
/**
 * Adds a language label to each `TranslatedData` object and converts them into `TranslatedContent` objects.
 *
 * This function takes a record of `TranslatedData` objects, converts it into an array, and maps each item
 * to a `TranslatedContent` object by adding a `langLabel` property. The `langLabel` is derived from
 * matching the `langCode` of each item with the available languages provided in `currentAvailableLanguages`.
 *
 * @param {Record<number, TranslatedData>} translatedContent - A record of `TranslatedData` objects keyed by a number.
 * @param {Option[]} currentAvailableLanguages - An array of available languages, each with an `id` and `label`.
 * @returns {TranslatedContent[]} An array of `TranslatedContent` objects, each containing a language label.
 */
export const addTranslatedLanguageLabelForCurrentModule = (
  translatedContent: Record<number, TranslatedData>,
  currentAvailableLanguages: Option[],
  includeTranslated = false
): TranslatedContent[] => {
  const array = Object.values(translatedContent);

  const translatedContentsArray: TranslatedContent[] = array?.map((item) => {
    if (includeTranslated) {
      const langLabel =
        currentAvailableLanguages?.find((lang) => lang.id === item?.langCode)
          ?.label || '';

      return {
        ...(item?.data?.content as GeneratedModuleContent),
        langCode: item?.langCode,
        langLabel,
      };
    }

    return {
      ...(item?.data?.content as GeneratedModuleContent),
    };
  });

  return translatedContentsArray;
};

// Used for adding translated language label to the `translatedContent` object in the `/generated-content` API response for each Email Module.
/**
 * Adds a language label to each `TranslatedContent` object based on a mapping configuration.
 *
 * This function processes arrays of content in primary language (sorted based on version sequence)
 * and translated content, using a translation configuration and a list of all available languages
 * to assign a language label to each translated content item. The language label is derived from
 * matching the content's ID with the translation configuration and finding the corresponding language
 * in the available languages list.
 *
 * @param {GeneratedModuleContent[]} sortedContentsArray - An array of sorted `GeneratedModuleContent` objects.
 * @param {GeneratedModuleContent[]} translatedContentsArray - An array of `GeneratedModuleContent` objects to be labeled.
 * @param {Record<string, string>} translationConfig - A mapping of content indices to language codes.
 * @param {Option[]} currentAvailableLanguages - An array of available languages, each with `id`, `label` and other properties.
 * @returns {TranslatedContent[]} An array of `TranslatedContent` objects, each annotated with a language label.
 *
 * @example
 * const sortedContents = [{ id: '1', name: 'Content1' }, { id: '2', name: 'Content2' }];
 * const translatedContents = [{ id: '1', name: 'Content1' }, { id: '2', name: 'Content2' }];
 * const translationConfig = { '0': 'en', '1': 'es' };
 * const languages = [{ id: 'en', label: 'English' }, { id: 'es', label: 'Spanish' }];
 * const labeledContents = addLanguageLabelToTranslatedContent(sortedContents, translatedContents, translationConfig, languages);
 * console.log(labeledContents);
 * // Output: [
 * //   { id: '1', name: 'Content1', langLabel: 'English' },
 * //   { id: '2', name: 'Content2', langLabel: 'Spanish' }
 * // ]
 */
export const addTranslatedLanguageLabelForSelectedModule = (
  sortedContentsArray: GeneratedModuleContent[],
  translatedContentsArray: GeneratedModuleContent[],
  translationConfig: Record<string, string>,
  currentAvailableLanguages: Option[]
): TranslatedContent[] => {
  const versionSequenceMapping: Record<string, string> = {};

  sortedContentsArray?.forEach((content, index) => {
    const id = get(content, 'id', '');
    if (id) versionSequenceMapping[id] = translationConfig[index];
  });

  const updatedTranslatedContentsArray: TranslatedContent[] =
    translatedContentsArray?.map((content) => {
      const translatedContent = { ...content };
      let langLabel = '';
      let langCode = '';

      if (
        versionSequenceMapping?.[content?.id] &&
        versionSequenceMapping?.[content?.id].trim() !== ''
      ) {
        langLabel =
          currentAvailableLanguages?.find(
            (lang) => lang.id === versionSequenceMapping?.[content?.id]
          )?.label || '';

        langCode = versionSequenceMapping?.[content?.id];
      }

      return { ...translatedContent, langCode, langLabel };
    });

  return updatedTranslatedContentsArray;
};
