import get from 'lodash/get';
import { translate } from '../localization';
import { Data } from '../types/Dashboard';
import { MedicalContentType } from '../types/Medical';
import {
  ActionLevel,
  BannerContentType,
  BannerFrameType,
  BannerFramesListType,
  ContentsDataType,
  SectionsType,
  SocialContent,
  GeneratedSocialContent,
  ContentOptions,
  GeneratedModuleContent,
} from '../types/Content';
import {
  APIRoleType,
  ListUserType,
  UserListType,
  UsersTransformedType,
} from '../types/Users';
import { WfTaskResponseData } from '../types/WfData';
import { DSPGeneratedContent } from '../components/molecules/DSPRenderer/DSPRenderer.types';

/** Constants for custom component prefixes */
export const headingWithLinesPrefix = '@headingWithLines';
export const subHeadingPrefix = '@subHeading';
export const spacer = '@spacer';

/* eslint-disable */

export function getTransformedBannerData(
  bannerRawStructure: any,
  lang: string
): ContentsDataType[] {
  const bannerTranslation = translate('transformers_option', {}, lang);
  const frameTranslation = translate('transformers_frame', {}, lang);

  const contents = bannerRawStructure.generatedContent.map(
    (el: any, i: number) => {
      const { frames } = el;
      let bodyContent = '';
      frames.forEach((frame: any, j: number) => {
        const { body } = frame;
        bodyContent += `__${frameTranslation} ${j + 1}:__ ${body}`;
        bodyContent += `\n\n`;
      });

      return {
        title: `${bannerTranslation} ${i + 1}`,
        sections: [
          {
            name: '',
            actionLevel: ActionLevel.SECTION,
            body: bodyContent,
          },
        ],
      };
    }
  );

  return contents;
}

export function getTransformedBannerContent(
  generatedContent: BannerFramesListType[]
): BannerContentType[] {
  const bannerTranslation = translate('transformers_option');
  const frameTranslation = translate('transformers_frame');
  const callToActionLabel = translate('common_call_to_action');

  const { contentOptions } = generatedContent[0];

  return contentOptions.map((content: ContentOptions, i: number) => {
    const { frames, optionId } = content;
    const bodyContent: string[] = [];
    frames.forEach((frame: BannerFrameType, j: number) => {
      const { body } = frame;
      if (frames.length === j + 1 && body) {
        const normalizedFrame = body
          .replace(/\r\n/g, '\n')
          .replace(/\r/g, '\n');
        const [frameText, callToActionContent] = normalizedFrame?.split(/\n/i);
        bodyContent.push(`__${frameTranslation} ${j + 1}:__ ${frameText}`);
        if (callToActionContent) {
          const [, callToActionText] = callToActionContent.split(':');
          bodyContent.push(
            `__${callToActionLabel.trim()}:__ ${callToActionText}`
          );
        }
      } else {
        bodyContent.push(`__${frameTranslation} ${j + 1}:__ ${body}`);
      }
    });

    return {
      title: `${bannerTranslation} ${i + 1}`,
      frames: [...bodyContent],
      isLoading: false,
      optionId,
    };
  });
}

export const getTransformedCannedParameters = (data: Data) => {
  /* eslint-disable */
  const formattedData: any = { brands: [] };

  data.brands.forEach((brand) => {
    const { id: brandId } = brand;

    formattedData.brands.push({ ...brand, value: brand.id });
    fillOptionsRelativeToBrand(
      brandId,
      formattedData,
      data.targetAudiences,
      'targetAudiences'
    );
    fillOptionsRelativeToBrand(brandId, formattedData, data.tones, 'tones');
  });

  fillOptionsRelativeToBrandAndTargetAudience(
    formattedData,
    data.personas,
    'personas'
  );
  fillOptionsRelativeToBrandAndTargetAudience(
    formattedData,
    data.contentTypes,
    'contentTypes'
  );

  fillOptionsRelativeToBrandAndTargetAudience(
    formattedData,
    data.topics,
    'topics'
  );
  return formattedData;
};

const fillOptionsRelativeToBrand = (
  brandId: string,
  target: any,
  iterator: Array<any>,
  key: string
) => {
  iterator.forEach((item) => {
    const { id: itemId, label: itemLabel, brands = [] } = item;

    if (brands.includes(brandId)) {
      if (!target[brandId]) {
        target[brandId] = {};
      }

      if (!target[brandId][key]) {
        target[brandId][key] = [];
      }
      target[brandId][key].push({
        id: itemId,
        label: itemLabel,
        value: itemId,
      });
    }
  });
};

const fillOptionsRelativeToBrandAndTargetAudience = (
  target: any,
  iterator: Array<any>,
  key: string
) => {
  const disabledContentTypeOptions = ['2', '3'];
  /* eslint-disable */
  iterator.forEach((item: any) => {
    const { id, label, brandTargetAudienceMapping = [] } = item;

    brandTargetAudienceMapping.forEach((mapping: any) => {
      const { brandId, targetAudienceId } = mapping;

      if (!target[brandId]) {
        target[brandId] = {};
      }

      if (!target[brandId][targetAudienceId]) {
        target[brandId][targetAudienceId] = {};
      }

      if (!target[brandId][targetAudienceId][key]) {
        target[brandId][targetAudienceId][key] = [];
      }

      if (key === 'contentType') {
        if (!disabledContentTypeOptions.includes(id)) {
          target[brandId][targetAudienceId][key].push({
            id,
            label,
            value: id,
          });
        }
      } else {
        target[brandId][targetAudienceId][key].push({
          id,
          label,
          value: id,
        });
      }
    });
  });
};

/* eslint-disable */
export function getTransformedMedicalData(summary: any): MedicalContentType[] {
  const {
    generatedContent: { sections },
  } = summary;
  const headline = sections.find(
    (section: SectionsType) => section.name === 'title'
  );
  const source = sections.find(
    (section: SectionsType) => section.name === 'citation'
  );

  const contents = [
    {
      title: translate('medical_content_headline_options'),
      sections: [
        {
          name: '',

          body: headline.body,
        },
      ],
    },
    {
      title: translate('medical_content_main_content'),
      sections: sections.filter(
        (section: SectionsType) =>
          section.name !== 'title' && section.name !== 'citation'
      ),
    },
    {
      title: translate('medical_content_source_attributes'),
      sections: [
        {
          name: '',
          body: source.body,
        },
      ],
    },
  ];
  return contents;
}

export function getTransformedEmailModuleData(
  generatedContent: GeneratedModuleContent[]
): GeneratedModuleContent[] {
  return generatedContent;
}

export function getTransformedDSPModuleData(data: any): DSPGeneratedContent[] {
  return data as DSPGeneratedContent[];
}

export const transformUserDetails = (user: ListUserType) => {
  const { id, email, firstName, lastName, clientId, roles } = user;
  return {
    id,
    email,
    clientId,
    firstName,
    lastName,
    fullName: `${firstName || ''} ${lastName || ''}`,
    role: get(roles, '[0].roleCode', ''),
    roles: get(roles, '[0]', {}) as APIRoleType,
    isRoleBeingUpdated: false,
  };
};

export const getTransformedUsersData = ({
  users = [],
}: UserListType): UsersTransformedType[] => {
  return users.map(transformUserDetails);
};

export function getTransformedSocialData(
  generatedContent: any
): SocialContent[] {
  const optionTitle = translate('transformers_option');
  const shortFormCopy = translate('transformers_social_short_form');
  const longFormCopy = translate('transformers_social_long_form');
  const socialHeadlineCopy = translate('transformers_social_headline');
  const socialBodyCopy = translate('transformers_social_body');
  const socialHastagsCopy = translate('transformers_social_hastags');
  const { contentOptions } = generatedContent[0];
  const contents = contentOptions.map(
    (section: GeneratedSocialContent, index: number) => {
      const { optionId, longForm, shortForm } = section;
      return {
        title: `${optionTitle} ${index + 1}`,
        optionId: optionId,
        isLoading: false,
        shortForm: {
          name: 'shortform',
          title: shortFormCopy,
          isLoading: false,
          headline: {
            name: 'headline',
            title: socialHeadlineCopy,
            text: shortForm.headline,
          },
          body: {
            name: 'body',
            title: socialBodyCopy,
            text: shortForm.body,
          },
          hashtags: {
            name: 'hashtag',
            title: socialHastagsCopy,
            text: shortForm.hashtags
              .map((str) => (str.startsWith('#') ? str : `#${str}`))
              .join(', '),
          },
        },
        longForm: {
          name: 'longform',
          title: longFormCopy,
          isLoading: false,
          headline: {
            name: 'headline',
            title: socialHeadlineCopy,
            text: longForm.headline,
          },
          body: {
            name: 'body',
            title: socialBodyCopy,
            text: longForm.body,
          },
          hashtags: {
            name: 'hashtag',
            title: socialHastagsCopy,
            text: longForm.hashtags
              .map((str) => (str.startsWith('#') ? str : `#${str}`))
              .join(', '),
          },
        },
      };
    }
  );

  return contents;
}

export const getTransformedPromptData = (data: WfTaskResponseData) => {
  const { brand, indication, audience, channel, platform, ...rest } = data;
  const isBrandAvailable = !!brand;
  const isAudienceAvailable = !!audience;
  const isChannelAvailable = !!channel;
  return {
    brand: isBrandAvailable ? brand || '' : '',
    indication: isBrandAvailable ? indication || '' : '',
    audience: isBrandAvailable ? audience || '' : '',
    channel: isBrandAvailable && isAudienceAvailable ? channel || '' : '',
    platform:
      isBrandAvailable &&
      isAudienceAvailable &&
      isChannelAvailable &&
      channel.toLowerCase() === 'social'
        ? platform || ''
        : '',
    ...rest,
  };
};
