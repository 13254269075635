import { AxiosProgressEvent } from 'axios';
// import { RESET } from 'jotai/utils';
import {
  deleteFileRequestApiCall,
  fileProcessStatusCheckApiCall,
  getSessionDetailsApiCall,
  intiateFileProcessApiCall,
  intiateFileUploadApiCall,
  uploadFileApiCall,
} from '../../../services/FileUpload';
import { fileUploadSegment } from '../stateSegments/fileUploadSegment';

import store from '../../markettingContentGeneration';
import {
  ANALYTICS,
  fileProcessStatuses,
  fileUploadStatuses,
  CONTENT_EXTRACTION,
} from '../../../constants/common';
import {
  trackFileExtractionComplete,
  trackFileExtractionError,
  trackFileExtractionInitiate,
  trackFileUploadComplete,
  trackFileUploadError,
  trackFileUploadInitiate,
} from '../../../services/Analytics';
import { trackfileUploadUsage } from '../../../services/Analytics/fileUpload';
import { getEncodedUserNtid } from '../../markettingContentGeneration/helpers/common';
import { trackfileExtractionUsage } from '../../../services/Analytics/fileExtraction';
import { getExtractionDetails } from '../../../services/Content';
import { ExtractionDetailsFuncResp } from '../../../types/ExtractionDetails';
import { extractionDetailsSegment } from '../stateSegments/extractionDetailsSegment';

let controller = new AbortController();
let { signal } = controller;

const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
  const percentCompleted = Math.round(
    (progressEvent.loaded * 100) / (progressEvent.total || 100)
  );
  store.set(fileUploadSegment, (prev) => ({
    ...prev,
    fileUploadPercentage: percentCompleted,
  }));
};

/**
 * Simulates uploading a file to a private URL with a delay to mimic real-time file upload.
 * This function sets the loading state, simulates a delay, and then updates the state to
 * indicate that the file has been successfully uploaded.
 */
export const uploadFileToPrivateUrl = async (
  preSignedURL: string,
  file: File
): Promise<void> => {
  // Set the initial loading state
  store.set(fileUploadSegment, (prev) => ({
    ...prev,
    isLoading: true,
    fileUploading: true,
    error: null,
    success: null,
  }));

  try {
    if (process.env.NODE_ENV === 'development') {
      setTimeout(() => {
        onUploadProgress({
          loaded: 50,
          total: 100,
          bytes: 0,
          lengthComputable: false,
        });
      }, 500);
    }

    const response = await uploadFileApiCall(
      preSignedURL,
      file,
      signal,
      onUploadProgress
    );

    if (response.status === 200) {
      // Update the state to indicate the file has been uploaded
      store.set(fileUploadSegment, (prev) => ({
        ...prev,
        isLoading: false,
        fileUploading: false,
        fileUploaded: true,
        fileUploadPercentage: 100,
        error: null,
      }));
      const { sessionId, fileName } = store.get(fileUploadSegment);

      // track analytics for file upload complete
      trackFileUploadComplete(ANALYTICS.FILE_UPLOAD_FORM_NAME);

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      intiateFileProcess(
        sessionId as string,
        fileName,
        fileUploadStatuses.FILE_UPLOAD_COMPLETED
      );
    } else {
      store.set(fileUploadSegment, (prev) => ({
        ...prev,
        isLoading: false,
        fileUploading: false,
        fileUploaded: false,
        fileUploadError: true,
        fileUploadPercentage: 0,
        error: 'Your file failed to upload. Please try uploading again.',
        success: null,
      }));

      // track analytics for file upload initiate
      trackFileUploadError(ANALYTICS.FILE_UPLOAD_FORM_NAME);
    }
  } catch (error) {
    // Update the state to indicate the file has been uploaded
    store.set(fileUploadSegment, (prev) => ({
      ...prev,
      isLoading: false,
      fileUploading: false,
      fileUploaded: false,
      fileUploadError: true,
      fileUploadPercentage: 0,
      error: 'Your file failed to upload. Please try uploading again.',
      success: null,
    }));

    // track analytics for file upload initiate
    trackFileUploadError(ANALYTICS.FILE_UPLOAD_FORM_NAME);
  }
};

export const intiateFileUpload = async (file: File) => {
  const { name: fileName } = file;
  store.set(fileUploadSegment, (prev) => {
    return {
      ...prev,
      isLoading: true,
      fileUploading: true,
      fileDeleted: false,
      fileName,
      error: null,
      success: null,
    };
  });

  // track analytics for file upload initiate
  trackFileUploadInitiate(ANALYTICS.FILE_UPLOAD_FORM_NAME);

  // track the ntid of the user
  trackfileUploadUsage(ANALYTICS.FILE_UPLOAD_FORM_NAME, getEncodedUserNtid());

  const { success, data } = await intiateFileUploadApiCall(fileName);

  if (success && data) {
    const { sessionId, fileUpload } = data;
    if (fileUpload.status !== fileUploadStatuses.FILE_UPLOAD_ERROR) {
      store.set(fileUploadSegment, (prev) => {
        return {
          ...prev,
          isLoading: false,
          fileName,
          sessionId,
        };
      });
      uploadFileToPrivateUrl(fileUpload.preSignedURL, file);
    } else {
      // track analytics for file upload error
      trackFileUploadError(ANALYTICS.FILE_UPLOAD_FORM_NAME);
      store.set(fileUploadSegment, (prev) => {
        return {
          ...prev,
          isLoading: false,
          fileUploading: false,
          fileUploadError: true,
          error: 'Your file failed to upload. Please try uploading again.',
          success: null,
          fileName,
          sessionId,
        };
      });
    }
  } else {
    // track analytics for file upload error
    trackFileUploadError(ANALYTICS.FILE_UPLOAD_FORM_NAME);
    store.set(fileUploadSegment, (prev) => {
      return {
        ...prev,
        isLoading: false,
        fileUploading: false,
        fileUploadError: true,
        error: 'Your file failed to upload. Please try uploading again.',
        success: null,
        fileName: '',
      };
    });
  }
};

export const intiateFileProcess = async (
  sessionId: string,
  fileName: string,
  fileUploadStatus: string
) => {
  store.set(fileUploadSegment, (prev) => {
    return {
      ...prev,
      isLoading: true,
      error: null,
      success: null,
    };
  });

  // track analytics for file extraction initiate
  trackFileExtractionInitiate(ANALYTICS.FILE_EXTRACTION_FORM_NAME);

  // track the ntid for file extraction
  trackfileExtractionUsage(
    ANALYTICS.FILE_EXTRACTION_FORM_NAME,
    getEncodedUserNtid()
  );

  const { success, data } = await intiateFileProcessApiCall(
    sessionId,
    fileName,
    fileUploadStatus
  );

  if (success && data) {
    const { fileProcess } = data;

    if (
      fileProcess.fileProcessStatus !== fileProcessStatuses.FILE_PROCESS_ERROR
    ) {
      store.set(fileUploadSegment, (prev) => {
        return {
          ...prev,
          isLoading: false,
          fileProcessing: true,
          sessionId,
        };
      });
    } else {
      store.set(fileUploadSegment, (prev) => {
        return {
          ...prev,
          isLoading: false,
          fileProcessing: false,
          fileProcessingError: true,
          error: 'Your file failed to processed. Please try uploading again.',
        };
      });

      // track analytics for file extraction error
      trackFileExtractionError(ANALYTICS.FILE_EXTRACTION_FORM_NAME);
    }
  } else {
    store.set(fileUploadSegment, (prev) => {
      return {
        ...prev,
        isLoading: false,
        fileProcessing: false,
        fileProcessingError: true,
        error: 'Your file failed to processed. Please try uploading again.',
      };
    });

    // track analytics for file extraction error
    trackFileExtractionError(ANALYTICS.FILE_EXTRACTION_FORM_NAME);
  }
};

export const validateFileProcess = async (sessionId: string) => {
  store.set(fileUploadSegment, (prev) => {
    return {
      ...prev,
      isLoading: true,
      error: null,
      success: null,
    };
  });
  const { success, data } = await fileProcessStatusCheckApiCall(sessionId);

  if (success && data) {
    const { fileProcessStatus } = data;
    const status = fileProcessStatus.fileProcessStatus;
    const { fileDeleted } = store.get(fileUploadSegment);
    if (!fileDeleted) {
      let fileProcessedError = false;
      if (status === fileProcessStatuses.FILE_PROCESS_COMPLETED) {
        const extractionDetails = await getExtractionDetails(sessionId);
        const { data: extractionData } =
          extractionDetails as ExtractionDetailsFuncResp;
        store.set(extractionDetailsSegment, () => extractionData);
        fileProcessedError = extractionData.fileUpload.topics.length === 0;
      }
      store.set(fileUploadSegment, (prev) => {
        return {
          ...prev,
          fileName: fileProcessStatus?.fileName || '',
          isLoading: false,
          sessionLoading: false,
          sessionLoaded: true,
          fileProcessing: status === fileProcessStatuses.FILE_PROCESS_INITIATED,
          fileProcessed: status === fileProcessStatuses.FILE_PROCESS_COMPLETED,
          fileProcessingError:
            status === fileProcessStatuses.FILE_PROCESS_ERROR,
          fileProcessedError,
          fileUploading: status === fileUploadStatuses.FILE_UPLOAD_INITIATED,
          fileUploaded:
            status === fileUploadStatuses.FILE_UPLOAD_COMPLETED ||
            status === fileProcessStatuses.FILE_PROCESS_INITIATED,
          fileUploadError: status === fileUploadStatuses.FILE_UPLOAD_ERROR,
          success:
            status === fileProcessStatuses.FILE_PROCESS_COMPLETED
              ? 'Your file has been processed successfully.'
              : null,
          error:
            status === fileProcessStatuses.FILE_PROCESS_ERROR
              ? 'Your file failed to processed. Please try uploading again.'
              : null,
          sessionId,
        };
      });
      if (status === fileProcessStatuses.FILE_PROCESS_COMPLETED) {
        sessionStorage.setItem('pfz-cggenai-session-id', sessionId || '');
        trackFileExtractionComplete(ANALYTICS.FILE_EXTRACTION_FORM_NAME);
      } else if (status === fileProcessStatuses.FILE_PROCESS_ERROR) {
        trackFileExtractionError(ANALYTICS.FILE_EXTRACTION_FORM_NAME);
      }
    }
  } else {
    store.set(fileUploadSegment, (prev) => {
      return {
        ...prev,
        fileName: data?.fileProcessStatus?.fileName || '',
        isLoading: false,
        sessionLoading: false,
        sessionLoaded: true,
        fileProcessing: false,
        fileProcessingError: true,
        error: 'Your file failed to processed. Please try uploading again.',
        success: null,
      };
    });

    // track analytics for file extraction error
    trackFileExtractionError(ANALYTICS.FILE_EXTRACTION_FORM_NAME);
  }
};

export const deleteFile = async (sessionId: string, fileName: string) => {
  store.set(fileUploadSegment, (prev) => {
    return {
      ...prev,
      isLoading: true,
      fileDeleting: true,
      error: null,
      success: null,
    };
  });
  controller.abort();
  controller = new AbortController();
  signal = controller.signal;
  clearInterval(window.uploadInterval);
  window.uploadInterval = null;
  const { success, data } = await deleteFileRequestApiCall(sessionId, fileName);

  if (success && data) {
    store.set(fileUploadSegment, {
      isLoading: false,
      fileName: '',
      fileUploaded: false,
      fileUploading: false,
      fileUploadError: false,
      fileProcessing: false,
      fileProcessed: false,
      fileProcessedError: false,
      fileProcessingError: false,
      sessionId: '',
      error: null,
      success: null,
      fileUploadPercentage: 0,
      fileDeleting: false,
      fileDeleted: true,
      sessionLoading: false,
      sessionLoaded: true,
    });
  } else {
    store.set(fileUploadSegment, (prev) => {
      return {
        ...prev,
        isLoading: false,
        fileDeleting: false,
        error: 'Failed to delete file',
      };
    });
  }
};

export const getSessionDetails = async (action: string): Promise<string> => {
  store.set(fileUploadSegment, (prev) => {
    return {
      ...prev,
      sessionLoading: true,
      error: null,
      success: null,
    };
  });
  const { success, data } = await getSessionDetailsApiCall(action);
  if (success && data) {
    const { userSessionData } = data;
    const lastSession = userSessionData.sessionsByAction.find(
      (session: { action: string; id: string; status: string }) =>
        session.action === CONTENT_EXTRACTION
    );
    if (lastSession) {
      const hasSessionId = !!lastSession.id;
      store.set(fileUploadSegment, (prev) => {
        return {
          ...prev,
          sessionId: lastSession.id,
          sessionLoading: hasSessionId,
          sessionLoaded: !hasSessionId,
        };
      });
      return lastSession.id;
    }
  }
  store.set(fileUploadSegment, (prev) => {
    return {
      ...prev,
      sessionId: '',
      sessionLoading: false,
      sessionLoaded: true,
      error: null,
    };
  });
  return '';
};
